// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-estate-planning-js": () => import("./../../../src/pages/estate-planning.js" /* webpackChunkName: "component---src-pages-estate-planning-js" */),
  "component---src-pages-general-practice-law-js": () => import("./../../../src/pages/general-practice-law.js" /* webpackChunkName: "component---src-pages-general-practice-law-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-disclaimer-js": () => import("./../../../src/pages/legal-disclaimer.js" /* webpackChunkName: "component---src-pages-legal-disclaimer-js" */),
  "component---src-pages-personal-injury-js": () => import("./../../../src/pages/personal-injury.js" /* webpackChunkName: "component---src-pages-personal-injury-js" */),
  "component---src-pages-practice-areas-js": () => import("./../../../src/pages/practice-areas.js" /* webpackChunkName: "component---src-pages-practice-areas-js" */),
  "component---src-pages-probate-administration-js": () => import("./../../../src/pages/probate-administration.js" /* webpackChunkName: "component---src-pages-probate-administration-js" */),
  "component---src-pages-real-estate-title-law-js": () => import("./../../../src/pages/real-estate-title-law.js" /* webpackChunkName: "component---src-pages-real-estate-title-law-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */)
}

