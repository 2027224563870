import { Link } from 'gatsby'
import React from 'react'

import './sideDrawer.css'

const sideDrawer = props => {
  let drawerClasses = 'side-drawer';

  if (props.show) {
    drawerClasses = 'side-drawer open'
  }

  return (
    <nav className={drawerClasses}>
      <ul className="mainmen">
        <li><Link to="/" onClick={props.drawerClickHandler}>HOME</Link></li>
        <li><Link to="/practice-areas/" onClick={props.drawerClickHandler}>PRACTICE AREAS</Link></li>
        <ul className="submen">
          <li><Link to="/general-practice-law/" onClick={props.drawerClickHandler}>General Practice</Link></li>
          <li><Link to="/personal-injury/" onClick={props.drawerClickHandler}>Personal Injury</Link></li>
          <li><Link to="/estate-planning/" onClick={props.drawerClickHandler}>Estate Planning & Wills</Link></li>
          <li><Link to="/real-estate-title-law/" onClick={props.drawerClickHandler}>Real Estate Titles & Law</Link></li>
          <li><Link to="/probate-administration/" onClick={props.drawerClickHandler}>Probate Administration</Link></li>
        </ul>
        <li><Link to="/resources/" onClick={props.drawerClickHandler}>RESOURCES</Link></li>
        <li><Link to="/about/" onClick={props.drawerClickHandler}>ABOUT</Link></li>
        <li><Link to="/contact/" onClick={props.drawerClickHandler}>CONTACT</Link></li>
      </ul>
      <div className="call-button">
          <a href="tel:603-504-6507">CALL JIM NOW</a>
      </div>
  </nav>
);

};



export default sideDrawer;
