import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion'
import Navbar from '../navbar/navbar'
import SideDrawer from '../sidedrawer/sideDrawer'
import Backdrop from '../backdrop/backdrop'

import './layout.css'

const duration = 0.3

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
}

const Layout = ({ children, location }) => {

  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [pageScrolled, setPageScrolled] = useState(false);

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(prevState => !prevState);
  };

  const menuEnterHandler = () => {
    setMenuOpen(true);
  };

  const menuLeaveHandler = () => {
    setMenuOpen(false);
  };

  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  };

  const onScroll = () => {
    let scrolled = false;
      if (window.scrollY > 0) {
        scrolled = true;
      }
      setPageScrolled(scrolled);
    };


  useEffect(() => {
      window.addEventListener('scroll', onScroll, false)
    });

  let backdrop;
  if (sideDrawerOpen) {
    backdrop = <Backdrop click={backdropClickHandler} />;
  }
  let classes = "drop-down menu-hide";
  if (menuOpen) {
    classes = "drop-down";
  }
  let navClasses = "navbar"
  if (pageScrolled) {
    navClasses = navClasses + ' sticky';
  }

  return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <div style={{ height: '100%' }}>
            <Navbar drawerClickHandler={drawerToggleClickHandler} siteTitle={data.site.siteMetadata.title} navClasses={navClasses} classes={classes} menuEnterHandler={menuEnterHandler} menuLeaveHandler={menuLeaveHandler} />
            <SideDrawer show={sideDrawerOpen} drawerClickHandler={drawerToggleClickHandler} />
            {backdrop}
            <AnimatePresence>
              <motion.main key={location.pathname}
                          variants={variants}
                          initial="initial"
                          animate="enter"
                          exit="exit">
                {children}
              </motion.main>
            </AnimatePresence>
            <footer>
              <div className="seacoastmhc">Law Office of James G. Feleen, PLLC</div>
              <div className="addr-phone">Claremont, NH - 603.504.6507</div>
              <div className="copyright">&copy;2019 James Feleen. Icons by Lorc & Delapouite <a href="https://game-icons.net" >here.</a></div>
            </footer>
          </div>
        )}
      />
    )
  }

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout